<template>
  <div class="coupons-update">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="main-content">
      <!-- <el-tabs v-model="activeType" class="demo-tabs">
        <el-tab-pane label="现金券" :name="1" disabled>
        </el-tab-pane>
        <el-tab-pane label="折扣券" :name="2" disabled>
        </el-tab-pane>
        <el-tab-pane label="兑换券" :name="3" disabled>
        </el-tab-pane>
      </el-tabs> -->
      <baseForm @submitForm="submitForm" :couponData="couponData" :activeType="activeType"></baseForm>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import baseForm from './cpns/base-form/base-form.vue'
import {updateCashCoupon,getCoupon} from '@/service/main/goods'
import { useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
import router from '@/router'
export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
    baseForm
  },
  setup() {
    const activeType = ref(1)
    const route = useRoute()
    
    const submitForm = async (payload) => {
      let obj = JSON.parse(JSON.stringify(payload))
      delete obj.date
      delete obj.saleDate
      if (obj.distributionType==1) {
        delete obj.salePrice
      }
      if (!obj.hasThreshold) {
        delete obj.thresholdPrice
      }
      if (obj.applicableScope==1) {
        delete obj.serveIdList
      }
      if (obj.validityType==1) {
        delete obj.days
      }else{
        delete obj.startDate
        delete obj.endDate
      }
      if (obj.effectiveWeekType==1) {
        delete obj.weekList
      }
      if (obj.effectivePeriodType==1) {
        delete obj.periodList
      }
      if (obj.shopPeriodType==1) {
        delete obj.shopIdList
      }
      if (obj.claimRestrictions==1) {
        delete obj.limitNumber
      }
      let res = await updateCashCoupon({...obj,type:activeType.value,id:route.query.editId})

      if (res.code == 0) {
        ElMessage({
          message: '修改成功!',
          type: 'success'
        })
        router.go(-1)
      }else{
        ElMessage({
          message: res.msg,
          type: 'error'
        })
      }
    }

    const couponData = ref(null)
    const getFormData = async ()=>{
      let data = await getCoupon(route.query.editId)
      couponData.value=data.data;
      activeType.value=data.data.type
    }

    const initPage = ()=>{
      if(route.query.editId){
        getFormData()
      }
      breadcrumbList[1].query={type:route.query.type}
      switch (route.query.type) {
        case '1':
          breadcrumbList[1].name='现金券列表'
          breadcrumbList[2].name='编辑现金券'
          break;
        case '2':
          breadcrumbList[1].name='折扣券列表'
          breadcrumbList[2].name='编辑折扣券'
          break;
        case '3':
          breadcrumbList[1].name='兑换券列表'
          breadcrumbList[2].name='编辑兑换券'
          break;
      
        default:
          breadcrumbList[1].name='现金券列表'
          breadcrumbList[2].name='编辑现金券'
          break;
      }
    }
    initPage()


    return {
      breadcrumbList,
      activeType,
      submitForm,
      getFormData,
      couponData
    }

  }
})
</script>

<style scoped lang="less">
.coupons-update {
  min-width: 1080px;

  .main-content {
    padding: 20px;
    margin: 20px;
    background-color: #fff;
  }
}
</style>




